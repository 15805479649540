// https://github.com/sw-yx/gatsby-netlify-form-example-v2/blob/master/src/pages/contact.js
import { navigate } from 'gatsby'
import { useState } from 'react'

import { Button, ButtonWrapper, Label } from '../../common/styles'

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}

const ContactForm = () => {
  const [formState, setFormState] = useState({
    email: '',
    place: '',
    date: '',
    time: '',
    wedding: false
  })

  const handleChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.value })
  }

  const handleCheckboxChange = e => {
    setFormState({ ...formState, [e.target.name]: e.target.checked })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const body = encode({
      'form-name': form.getAttribute('name'),
      subject: `margotmaquille.fr - nouvelle demande de ${formState.email}`,
      ...formState
    })

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body
    }).then(() => {
      navigate('/contact/success/')
    })
  }

  return (
    <form
      name='contact'
      method='post'
      action='/thanks/'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type='hidden' name='form-name' value='contact' />
      {/* Customize Netlify's email subject */}
      <input type='hidden' id='subject' name='subject' />
      <p hidden>
        <Label htmlFor='bot-field'>
          <input name='bot-field' onChange={handleChange} />
        </Label>
      </p>
      <p>
        <Label htmlFor='name'>
          Prénom et nom *
          <br />
          <input
            type='text'
            name='name'
            id='name'
            onChange={handleChange}
            required
          />
        </Label>
      </p>
      <p>
        <Label htmlFor='phone'>
          Téléphone *
          <br />
          <input
            type='text'
            name='phone'
            id='phone'
            onChange={handleChange}
            pattern='^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$'
            required
          />
        </Label>
      </p>
      <p>
        <Label htmlFor='email'>
          Email *
          <br />
          <input
            type='email'
            name='email'
            id='email'
            onChange={handleChange}
            required
          />
        </Label>
      </p>

      <p style={{ marginBottom: 28, display: 'flex', alignItems: 'center' }}>
        <Label
          htmlFor='wedding'
          style={{
            cursor: 'pointer',
            lineHeight: '28px',
            textDecoration: 'underline'
          }}
        >
          Je souhaite obtenir un devis pour une{' '}
          <strong>prestation mariage</strong>
        </Label>
        <input
          type='checkbox'
          name='wedding'
          id='wedding'
          onChange={handleCheckboxChange}
          style={{ cursor: 'pointer', marginTop: 0 }}
        />
      </p>

      <div style={{ display: formState.wedding ? 'block' : 'none' }}>
        <p>
          <Label htmlFor='place'>
            Adresse du lieu de la prestation le jour du mariage (si connue)
            <br />
            <input
              type='text'
              name='place'
              id='place'
              onChange={handleChange}
            />
          </Label>
        </p>
        <p>
          <Label htmlFor='date'>
            Date du mariage (si connue)
            <br />
            <input type='text' name='date' id='date' onChange={handleChange} />
          </Label>
        </p>
        <p>
          <Label htmlFor='time'>
            Heure à laquelle votre maquillage doit être terminé (si connue)
            <br />
            <input type='text' name='time' id='time' onChange={handleChange} />
          </Label>
        </p>
        <p>
          <Label htmlFor='guests'>
            Nombre de maquillages à réaliser pour vos invités (si connu)
            <br />
            <input
              type='text'
              name='guests'
              id='guests'
              onChange={handleChange}
            />
          </Label>
        </p>
      </div>
      <p>
        <Label htmlFor='message'>
          Message *
          <br />
          <textarea
            name='message'
            id='message'
            placeholder='Quelles sont vos attentes?'
            onChange={handleChange}
            style={{ height: 150 }}
            required
          />
        </Label>
      </p>
      <p>
        <Label htmlFor='origin'>
          Comment avez-vous connu Margot Maquille?
          <br />
          <input
            type='text'
            name='origin'
            id='origin'
            onChange={handleChange}
          />
        </Label>
      </p>
      <p style={{ marginBottom: 28 }}>* Champs obligatoires</p>
      <ButtonWrapper>
        <Button type='submit' large>
          Envoyer
        </Button>
      </ButtonWrapper>
    </form>
  )
}

export default ContactForm
